import React from "react";

import "./notebook.styles.css";

const Notebook = () => {
  return (
    <div className="notebook">
      <div className="outside-work">
        <div className="notebook-title">
          <p>Featured poetry at poetistic.com</p>
        </div>
        <a
          href="https://poetistic.com/writers/kanor"
          target="_blank"
          className="btn check-out-btn"
          rel="noreferrer"
        >
          Read The Poetry
        </a>
      </div>
    </div>
  );
};

export default Notebook;
