export const WORK_DATA = [
  {
    id: "1",
    header: "ML Framework for linear regression",
    title: "ML Framework Python and Java",
    description: [
      "ML framework for performing ml operations on linear regression dataset",
      "User can train test and get the score of the model.",
    ],
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/port1-min.jpg?raw=true",
    link: "https://pankajvyas.in/#/ml/lrmodel",
    btnText: "Read More",
  },
  {
    id: "2",
    header: "Kafka FluentD Newrelic",
    title: "Data Streaming",
    description: [
      "Transfer data seamlessly from Kafka to New Relic for enhanced analysis and insights.",
      "Streamline your data flow, ensuring efficient monitoring and analysis using New Relic's robust analytics platform.",
    ],
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/port2-min.jpg?raw=true",
    link: "https://github.com/pvyas033/data_streaming#readme",
    btnText: "Read More",
  },
  {
    id: "3",
    header: "Microservices Architecture",
    title: "Event Logging",
    description: [
      "Event-driven microservices architecture with patterns ",
      "using Spring boot, Spring cloud, Kafka and Elasticsearch.",
    ],
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/port3-min.jpg?raw=true",
    link: "https://github.com/pvyas033/event-driven-microservices#readme",
    btnText: "Read More",
  },
  {
    id: "4",
    header: "Interface",
    title: "Email Sender",
    description: [
      "REST API to send emails, user needs to provide",
      "excel file then it will extract emails and send",
    ],
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/port4-min.jpg?raw=true",
    link: "https://github.com/pvyas033/email-sender#readme",
    btnText: "Read More",
  },
  {
    id: "5",
    header: "Online Clothing Store",
    title: "Door Store",
    description: [
      "In learning of React, I built the website, designed",
      "the structure, and created standout site experience",
    ],
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/port5-min.jpg?raw=true",
    link: "https://pvyas033.github.io/crwn-clothing/#/",
    btnText: "Visit Site",
  },
  {
    id: "6",
    header: "Reporting",
    title: "Model To Report",
    description: [
      "Desktop Application that helps to create reports",
      "from reporting template and model data",
    ],
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/port6-min.jpg?raw=true",
    link: "https://github.com/pvyas033/reporting-tool#readme",
    btnText: "Read More",
  },
  {
    id: "7",
    header: "Specially crafted tea",
    title: "Chai Station",
    description: [
      "I built this website for extend tea services where",
      "user can order tea according to their taste",
    ],
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/port7-min.jpg?raw=true",
    link: "https://chai-station.netlify.app/",
    btnText: "Visit Site",
  },
  {
    id: "8",
    header: "Tours",
    title: "CONTINUE EXPLORING",
    description: [
      "Explore the world, I created this site for tour",
      "and travel services to get amazing experience",
    ],
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/port8-min.jpg?raw=true",
    link: "https://krishna-tours.netlify.app/",
    btnText: "Visit Site",
  },
];
