import React from "react";
import { Link } from "react-router-dom";

import logo from "./../../assets/img/logo.png";

import "./navbar.styles.css";

class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarOpen: "",
      toggle: true,
    };
  }

  openMenu() {
    if (this.state.toggle)
      this.setState({
        navbarOpen: "open",
        toggle: !this.state.toggle,
      });
    else {
      this.closeMenu();
    }
  }

  closeMenu() {
    this.setState({
      navbarOpen: "",
      toggle: !this.state.toggle,
    });
  }

  render() {
    return (
      <nav className={this.state.navbarOpen}>
        <div className="container">
          <Link to="/" className="option" onClick={() => this.closeMenu()}>
            <div className="logo">
              <img src={logo} alt="logo img" />
            </div>
          </Link>
          <div className="links">
            <ul>
              <li>
                <Link
                  to="/"
                  className="option"
                  onClick={() => this.closeMenu()}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/projects"
                  className="option"
                  onClick={() => this.closeMenu()}
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  to="/contact"
                  className="option"
                  onClick={() => this.closeMenu()}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div className="hamburger-menu" onClick={() => this.openMenu()}>
            <div className="bar"></div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
