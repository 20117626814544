import { combineReducers } from "redux";
import workReducer from "./work/work.reducer";
import techReducer from "./techs/tech.reducer";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["work", "capabilities"],
};

const rootReducer = combineReducers({
  work: workReducer,
  capabilities: techReducer,
});

export default persistReducer(persistConfig, rootReducer);
