import React from "react";
import { SocialIcon } from "react-social-icons";

import personImg1 from "./../../assets/img/front.gif";

import "./home.styles.css";

const Home = () => {
  return (
    <header id="header">
      <div className="header-content">
        <div className="container grid-2">
          <div className="column-1">
            <h1 className="header-title">
              I am a developer and artist who loves to craft a good story
            </h1>
            <div className="line" />
            <p className="description-text">
              With extensive experience in software engineering, specializing in <span style={{ color: 'orange' }}>Java, J2EE </span>, Web Development, Design Patterns, and Microservices
            </p>
            <p className="description-text">
              I offer expertise in <span style={{ color: 'orange' }}>Docker</span>, <span style={{ color: 'orange' }}>Kubernetes</span>, and Helm Charts for seamless cloud deployment. Committed to delivering clean code and effective debugging, I guarantee top-notch performance for your projects. Let's collaborate and bring your ideas to life!
            </p>
          </div>
          <div className="column-2 image ">
            <img src={personImg1} className="img-element z-index" alt="" />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Home;
