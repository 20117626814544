import React from "react";

import "./capabilities.styles.css";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Tech from "./tech/tech.component";
import { selectTech } from "../../redux/techs/tech.selectors";

const Capabilities = ({ techs }) => {
  return (
    <div className="capabilities-section">
      {techs.map(({ id, ...otherProperties }) => (
        <Tech key={id} {...otherProperties} />
      ))}
    </div>
  );
};

// state is root reducer
const mapStateToProps = createStructuredSelector({
  techs: selectTech,
});

export default connect(mapStateToProps)(Capabilities);
