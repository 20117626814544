import { Route, Switch } from "react-router";

import About from "./pages/about/about.component";
import Blogs from "./pages/blogs/blogs.component";
import Contact from "./pages/contact/contact.component";
import Home from "./pages/home/home.component";
import Work from "./pages/work/work.component";
import AutoDownload from "./pages/resume/resume.component";
import LRModelPY from "./components/mlfw/linear.regression/lrmodelpy.component";

import "./App.css";
import NotFound from "./pages/notfound/notfound.component";
import LRModelJDK from "./components/mlfw/linear.regression/lrmodel.component";

function App() {
  return (
    <div className="app">
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/about" component={About}></Route>
        <Route path="/projects" component={Work}></Route>
        <Route path="/contact" component={Contact}></Route>
        <Route path="/blogs" component={Blogs}></Route>
        <Route path="/download" component={AutoDownload}></Route>
        <Route path="/ml/lrmodel" component={LRModelPY}></Route>
        <Route path="/ml/lrmodelj" component={LRModelJDK}></Route>
        <Route path="*" component={NotFound} />
      </Switch>
    </div>
  );
}

export default App;
