export const TECH_DATA = [
  {
    id: "1",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/java.png?raw=true",
    description: "java",
  },
  {
    id: "2",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/c++.png?raw=true",
    description: "c++",
  },
  {
    id: "3",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/javascript.png?raw=true",
    description: "javascript",
  },
  {
    id: "4",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/html.png?raw=true",
    description: "html",
  },
  {
    id: "5",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/css.png?raw=true",
    description: "css",
  },
  {
    id: "6",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/fortify.png?raw=true",
    description: "fortify",
  },
  {
    id: "7",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/react.png?raw=true",
    description: "react",
  },
  {
    id: "8",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/git.png?raw=true",
    description: "git",
  },
  {
    id: "9",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/jira.png?raw=true",
    description: "jira",
  },
  {
    id: "10",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/spring.png?raw=true",
    description: "spring",
  },
  {
    id: "11",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/k8s.png?raw=true",
    description: "kubernetes",
  },
  {
    id: "12",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/kafka.png?raw=true",
    description: "kafka",
  },
  {
    id: "13",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/eclipse.png?raw=true",
    description: "eclipse",
  },
  {
    id: "14",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/blackduck.png?raw=true",
    description: "blackduck",
  },
  {
    id: "15",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/sql.png?raw=true",
    description: "sql",
  },
  {
    id: "16",
    bgImgUrl:
      "https://github.com/pvyas033/host/blob/master/img/docker.png?raw=true",
    description: "docker",
  },
];
