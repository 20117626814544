import React from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import Project from "../../components/project/project.component";
import { selectProjects } from "../../redux/work/work.selectors";
import Footer from "../../components/footer/footer.component";
import Navbar from "../../components/navbar/navbar.component";

import "./work.styles.css";

const Work = ({ projects }) => {
  return (
    <section id="portfolio">
      <Navbar />
      <div className="projects-section">
        <div className="projects">
          {projects.map(({ id, ...otherProperties }) => (
            <Project key={id} {...otherProperties} />
          ))}
        </div>
      </div>
      <div className="more-folio">
        <a
          href="https://github.com/pvyas033"
          target="_blank"
          rel="noreferrer"
          className="btn github-repo-btn"
        >
          GitHub Repo
          </a>
      </div>
      <Footer />
    </section>
  );
};

// state is root reducer
const mapStateToProps = createStructuredSelector({
  projects: selectProjects,
});

export default connect(mapStateToProps)(Work);
