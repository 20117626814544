import React from "react";
import Capabilities from "../../components/capabilities/capabilities.component";
import Navbar from "../../components/navbar/navbar.component";

import "./about.styles.css";

const About = () => {
  return (
    <section className="about" id="about">
      <div className="about-section-header">
        <h1 className="about-section-title">
          Pankaj is a developer who loves to tell a good story.
          </h1>
      </div>
      <div>
        <div className="about-me">
          <div className="about-img">
            <p className="about-text">
              I dream of the ocean, as endless as the ocean
            </p>
          </div>
          <div className="about-paras">
            <p className="about-para-title">About Pankaj</p>
            <p className="about-para">
              Pankaj is a software developer with servral years of experience.
              He has a unique talent at seeing the big picture potential in a
              product or brand and then bringing it to life.
            </p>
            <p className="about-para">
              Each company Pankaj has worked for has carried a strong mission
              behind the work—ranging from sustainability and transportation, to
              education. He tries to keep that same ethos for doing good in his
              personal life as well.
            </p>
            <p className="about-para">
              Pankaj is comfortable working on a range of projects from backend
              to end user experience with a production crew.
            </p>
            <p className="about-para">
              Outside of work Pankaj finds inspiration through poetry, music,
              and traveling. His hunger and curiosity for life drives him to
              experience everything and document all that it has to offer. This
              thirst for life is evident in all of his work. Pankaj doesn’t find
              a strong line between creativity, work, and play.
            </p>
          </div>
        </div>
      </div>
      <Capabilities />
      // we need to add images in host repo on pvyas github
    </section >
  );
};

export default About;
