import React from "react";

import Header from "../../components/home/home.component";
import Footer from "../../components/footer/footer.component";
import Navbar from "../../components/navbar/navbar.component";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Header />
      <Footer />
    </div>
  );
};

export default Home;
