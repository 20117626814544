import React from 'react';

import "./notfound.styles.css";
import Footer from "../../components/footer/footer.component";

const NotFound = () => {
    return (
      <div class="notfoundsection">
        <div class="error-box">
        <h1 class="error-code">404</h1>
        <h1 class="error-title">Oops! The page you are looking for does not exist.</h1>
        <h1 class="redirect-link">Click <a href="/">here</a> to redirect to the home page.</h1>
      </div>
      </div>
    );
};

export default NotFound;
